var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.homeOffer2 && _vm.homeOffer2.length ? _c('div', [_c('VueSlickCarousel', _vm._b({}, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.homeOffer2, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "containers-image text-center",
      attrs: {
        "id": "carousel-home-middle"
      }
    }, [_c('img', {
      staticClass: "image",
      attrs: {
        "src": image.url.fileUrl
      }
    })]);
  }), 0)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }